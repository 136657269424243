<template>
    <div class="term-extraction-detail-page">
        <full-loading ref="fullLoading"></full-loading>
        <page-title :title="info.title||''" style="border: none;" v-if="info">
            <template slot="info">
                <div class="more-info">
                    <el-popover trigger="hover">
                        <el-form label-width="80px" size="mini" class="more-info-popover">
                            <el-form-item :label="$t('tools.tools_termExtractionDetail_page_form_item_label_1')" v-if="LANGUAGE_DICT&&info">
                                {{LANGUAGE_DICT[info.sourceLang].shortName}} <i class="el-icon-arrow-right"></i> {{LANGUAGE_DICT[info.targetLang].shortName}}
                            </el-form-item>
                            <el-form-item :label="$t('tools.tools_termExtractionDetail_page_form_item_label_2')">{{info.resultCount}} {{$t('tools.tools_termExtractionDetail_page_tips_1')}}</el-form-item>
                            <el-form-item :label="$t('tools.tools_termExtractionDetail_page_form_item_label_3')">{{info.createTime}}</el-form-item>
                        </el-form>
                        <svg-icon name="ib-info" class-name="color-555" slot="reference"></svg-icon>
                    </el-popover>
                </div>
            </template>
            <template slot="tools">
                <div style="width:160px;margin-right:10px;">
                    <el-input :placeholder="$t('tools.tools_termExtractionDetail_page_input_placeholder_1')" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <el-button round plain size="small" @click="showBatchSaveDialog">{{$t('tools.tools_termExtractionDetail_page_btn_1')}}</el-button>
                <el-button size="small" plain round :disabled="tableSelection.length < 1" @click="e=>deleteBtnEvent(e,null)" style="margin-left:10px;"><svg-icon name="ib-trash-o"></svg-icon> {{$t('tools.tools_termExtractionDetail_page_btn_2')}}</el-button>
                <el-button type="primary" size="small" round v-permission="`tools:extraction:detailRouter:save`" @click="dialog.show = true"> {{$t('tools.tools_termExtractionDetail_page_btn_3')}}</el-button>
                <el-dropdown  @command="downloadFileEvent">
                    <el-button type="primary" round size="small" :loading="downloadLoading" style="margin-left:10px;">
                        {{$t('tools.tools_termExtractionDetail_page_btn_4')}} <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="excel" v-permission="`tools:extraction:detailRouter:downloadExcel`">{{$t('tools.tools_termExtractionDetail_page_tips_2')}}</el-dropdown-item>
                        <el-dropdown-item command="tbx" v-permission="`tools:extraction:detailRouter:downloadTBX`">{{$t('tools.tools_termExtractionDetail_page_tips_3')}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            
            </template>
        </page-title>
        <el-alert type="info" center effect="dark" :closable="false">
            <template slot="title">
                <span style="margin-left:10px;font-weight:400;font-size:12px;" v-if="info">
                    {{$t('tools.tools_termExtractionDetail_page_tips_4', {count: info.resultCount})}}
                </span>
            </template>
        </el-alert>
        <el-table ref="tableList" :data="list" @selection-change="handleTableSelectionChange" @row-click="handleTableRowClick" style="width: 100%;">
          <el-table-column type="selection" :selectable="(row,index)=>{return index !== 0}" width="50"></el-table-column>
          <el-table-column type="index" :label="$t('tools.tools_termExtractionDetail_page_table_column_label_1')" :index="tableIndexMethod" width="70"></el-table-column>
          <el-table-column width="200" prop="frequency">
            <template #header>
                <el-tooltip :content="$t('tools.tools_termExtractionDetail_page_tips_5')" placement="top">
                    <span>{{$t('tools.tools_termExtractionDetail_page_table_column_label_2')}} <svg-icon name="ib-question" className="color-999"></svg-icon></span>
                </el-tooltip>
                <svg-icon name="ib-equal-than" class="margin-l-5 fonts-12"></svg-icon>
                <el-input-number size="mini" v-model="sourceFrequency" :precision="0" :min="1" :max="200" @change="sourceFrequencyChange" style="width: 98px; margin-left: 5px;"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
                <span>{{$t('tools.tools_termExtractionDetail_page_table_column_label_3', {lang: info && LANGUAGE_DICT && LANGUAGE_DICT[info.sourceLang] && LANGUAGE_DICT[info.sourceLang].name})}}</span>
            </template>
            <template slot-scope="scope">
                <div :lang="info.sourceLang">
                    <template v-if="scope.row.confirmed === false">
                        <el-input size="small" v-model="scope.row.sourceText" @keydown.native.enter.stop="saveEditEvent(scope)" :placeholder="$t('tools.tools_termExtractionDetail_page_input_placeholder_2')"></el-input>
                    </template>
                    <template v-else>
                        <template v-if="scope.row.resultId">{{scope.row.sourceText}}</template>
                        <template v-else>
                            <el-input size="small" v-model="newSourceValue" @keydown.native.enter.stop="saveAddEvent" :placeholder="$t('tools.tools_termExtractionDetail_page_input_placeholder_2')"></el-input>
                        </template>
                    </template>
                </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('tools.tools_termExtractionDetail_page_table_column_label_4', {lang: info && LANGUAGE_DICT && LANGUAGE_DICT[info.targetLang] && LANGUAGE_DICT[info.targetLang].name})">
            <template slot-scope="scope">
                <div :lang="info.targetLang">
                    <template v-if="scope.row.confirmed === false">
                        <el-input size="small" v-model="scope.row.targetText" @keydown.native.enter.stop="saveEditEvent(scope)" :placeholder="$t('tools.tools_termExtractionDetail_page_input_placeholder_3')"></el-input>
                    </template>
                    <template v-else>
                        <template v-if="scope.row.resultId">{{scope.row.targetText}}</template>
                        <template v-else>
                            <el-input size="small" v-model="newTargetValue" @keydown.native.enter.stop="saveAddEvent" :placeholder="$t('tools.tools_termExtractionDetail_page_input_placeholder_3')"></el-input>
                        </template>
                    </template>
                </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('tools.tools_termExtractionDetail_page_table_column_label_5')">
                <template slot-scope="scope">
                    <div>
                        <template v-if="scope.row.confirmed === false">
                            <el-input size="small" maxlength="100" show-word-limit v-model="scope.row.description" :placeholder="$t('tools.tools_termExtractionDetail_page_input_placeholder_4')" @keydown.native.enter="saveEditEvent(scope)"></el-input>
                        </template>
                        <template v-else>
                            <template v-if="scope.row.resultId">{{scope.row.description}}</template>
                            <template v-else>
                                <el-input size="small" maxlength="100" show-word-limit v-model="newRemarkValue" :placeholder="$t('tools.tools_termExtractionDetail_page_input_placeholder_4')" @keydown.native.enter="saveAddEvent"></el-input>
                            </template>
                        </template>
                    </div>
                </template>
            </el-table-column>
          <el-table-column :label="$t('tools.tools_termExtractionDetail_page_table_column_label_6')" width="120">
              <template slot-scope="scope">
                     <template v-if="scope.row.confirmed === false">
                         <el-tooltip :content="$t('tools.tools_termExtractionDetail_page_tips_6')" placement="top"><span class="table-col-icon" @click="saveEditEvent(scope)"><svg-icon name="ib-check-o"></svg-icon></span></el-tooltip>
                         <el-tooltip :content="$t('tools.tools_termExtractionDetail_page_tips_7')" placement="top"><span class="table-col-icon" @click="e=>deleteBtnEvent(e,scope.row)"><svg-icon name="ib-trash-o"></svg-icon></span></el-tooltip>
                     </template>
                     <template v-else>
                         <template v-if="scope.row.resultId">
                            <el-tooltip :content="$t('tools.tools_termExtractionDetail_page_tips_8')" placement="top"><span class="table-col-icon" @click="editBtnEvent(scope)"><svg-icon name="ib-edit"></svg-icon></span></el-tooltip>
                            <el-tooltip :content="$t('tools.tools_termExtractionDetail_page_tips_7')" placement="top"><span class="table-col-icon" @click="e=>deleteBtnEvent(e,scope.row)"><svg-icon name="ib-trash-o"></svg-icon></span></el-tooltip>
                         </template>
                         <template v-else>
                             <el-tooltip :content="$t('tools.tools_termExtractionDetail_page_tips_9')" placement="top"><span class="table-col-icon" @click="saveAddEvent"><svg-icon name="ib-check-o"></svg-icon></span></el-tooltip>
                         </template>
                     </template>
                 </template>
          </el-table-column>
        </el-table>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog :title="$t('tools.tools_termExtractionDetail_page_dialog_1_title')" :visible.sync="dialog.show" @open="saveToTermDialogOpenEvent" destroy-on-close width="580px" top="20px">
            <el-form label-width="90px" style="padding:0 20px;">
                <div class="text-center fonts-14 margin-b-20" style="color:#FF9900;"><svg-icon name="ib-warning"></svg-icon> {{$t('tools.tools_termExtractionDetail_page_dialog_1_tips_1')}}</div>
                <el-form-item :label="$t('tools.tools_termExtractionDetail_page_dialog_1_form_item_label_1')">
                    <el-autocomplete 
                        suffix-icon="el-icon-arrow-down"
                        @keypress.native.enter.prevent
                        v-model="dialog.name" :placeholder="$t('tools.tools_termExtractionDetail_page_dialog_1_input_placeholder_1')" style="width:100%;"
                        :fetch-suggestions="queryTermList"
                        @select="dialogTermNameSelectEvent"></el-autocomplete>
                </el-form-item>
                <el-form-item :label="$t('tools.tools_termExtractionDetail_page_dialog_1_form_item_label_2')" v-if="userInfo.organizationType === 'ENTERPRISE'">
                    <el-select v-model="dialog.team" :placeholder="$t('tools.tools_termExtractionDetail_page_dialog_1_input_placeholder_2')">
                      <template v-for="item in dialog.teamOptions">
                        <el-option :label="item.text" :value="item.value" :key="item.value"></el-option>
                      </template>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center;">
                <el-button round plain size="small" @click="dialog.show = false">{{$t('tools.tools_termExtractionDetail_page_dialog_1_btn_1')}}</el-button>
                <el-button round type="primary" size="small" :loading="dialog.loading" @click="saveToTermEvent">{{$t('tools.tools_termExtractionDetail_page_dialog_1_btn_2')}}</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteConfirmEvent"
            @cancelEvent="deleteCancelEvent"></alert-dialog>
        <alert-dialog ref="alertDialogDomBatchSave" 
            :message="batchAlertInfo.message" 
            :type="batchAlertInfo.type" 
            :buttonName="batchAlertInfo.buttonName"
            :buttonType="batchAlertInfo.buttonType"
            @confirmEvent="bathSaveEvent"></alert-dialog>
    </div>
</template>
<script>
import $ from 'jquery';
import { mapState } from 'vuex';
import FullLoading from '@/components/FullLoading';
import fileDownload from 'js-file-download';
import config from '@/utils/config';
import { toolsApi, termApi, commonApi } from '@/utils/api';
import PageTitle from '@/components/PageTitle';
import AlertDialog from '@/components/AlertDialog';
export default {
    components:{PageTitle, AlertDialog, FullLoading},
    data(){
        return {
            alertInfo:{
                message: this.$t('tools.tools_termExtractionDetail_page_data_alertInfo_message'),
                type:'confirm', //alert ,confirm
                buttonName: this.$t('tools.tools_termExtractionDetail_page_data_alertInfo_buttonName'),
                buttonType:'danger',
            },
            batchAlertInfo:{
                message: this.$t('tools.tools_termExtractionDetail_page_data_batchAlertInfo_message'),
                type:'confirm', //alert ,confirm
                buttonName: this.$t('tools.tools_termExtractionDetail_page_data_batchAlertInfo_buttonName'),
                buttonType:'primary',
            },
            info:null,
            dialog:{
                show:false,
                loading:false,
                name: '',
                termId: '',
                team: '',
                teamOptions: [],
            },
            searchForm:{
                kw:''
            },
            list:[],
            total:0,
            pageSize:30,
            pageNumber:1,
            newSourceValue:'',
            newTargetValue:'',
            newRemarkValue: '',
            tableSelection: [],
            downloadLoading: false,
            batchSaveLoading: false,
            sourceFrequency: 1,
            sourceFrequencyTimer: null,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    methods:{
        initPage(loading){
            if(loading){
                this.$refs.fullLoading.setShow();
            }
            let url = `${toolsApi.queryETResultlist}` +
                        `?taskId=${this.$route.query.id}` +
                        `&pageNo=${this.pageNumber}` +
                        `&pageSize=${this.pageSize}` +
                        `&keyword=${encodeURI(this.searchForm.kw)}` +
                        `&minFrequency=${this.sourceFrequency}` +
                        `&maxFrequency=`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.info = res.data.task;
                    this.list = [{resultId:null,sourceText:'',targetText:''}].concat(res.data.pager.records);
                    this.total = res.data.pager.total;
                    if(loading){
                        this.$nextTick(()=>{
                            $($('.el-input')[3]).find('input').focus();
                        });
                    }
                }
            }).finally(()=>{
                if(loading){
                    this.$refs.fullLoading.setHide();
                }
            });
        },
        handleCurrentChange(val){
            this.pageNumber = val;
            this.initPage();
        },
        inputSearchEvent(value){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                self.initPage(false);
            },500);
        },
        tableIndexMethod(index) {
            if(index === 0) return '–'
            return index;
        },
        editBtnEvent(scope){
            let row=scope.row;
            this.list.splice(scope.$index,1,{...this.list[scope.$index],confirmed:false});
        },
        saveEditEvent(scope){
            let row = scope.row;
            let index = scope.$index;
            if(!row.sourceText){
                this.$message.error(this.$t('tools.tools_termExtractionDetail_page_method_saveEditEvent_message_1'));
                return;
            }
            if(!row.targetText){
                this.$message.error(this.$t('tools.tools_termExtractionDetail_page_method_saveEditEvent_message_2'));
                return;
            }
            let url = toolsApi.updateETResult;
            let postData = {
                sourceText:row.sourceText,
                targetText:row.targetText,
                description: row.description,
                resultId:scope.row.resultId,
                taskId:this.$route.query.id,
            }
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_termExtractionDetail_page_method_saveEditEvent_message_3'));
                    this.initPage();
                }
            })
        },
        saveAddEvent(){
            if(!this.newSourceValue){
                this.$message.error(this.$t('tools.tools_termExtractionDetail_page_method_saveAddEvent_message_1'));
                return;
            }
            if(!this.newTargetValue){
                this.$message.error(this.$t('tools.tools_termExtractionDetail_page_method_saveAddEvent_message_2'));
                return;
            }
            let url = toolsApi.createETResult;
            let postData={
                taskId: this.$route.query.id,
                sourceText: this.newSourceValue,
                targetText: this.newTargetValue,
                description: this.newRemarkValue, 
            }
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_termExtractionDetail_page_method_saveAddEvent_message_3'));
                    this.newSourceValue = '';
                    this.newTargetValue = '';
                    this.newRemarkValue = '';
                    this.pageNumber = 1;
                    this.initPage();
                }
            })
        },
        showBatchSaveDialog(){
            this.$refs.alertDialogDomBatchSave.setShow(true);
        },
        bathSaveEvent(){
            let url = toolsApi.batchSaveResult;
            let postData = {
                taskId:this.$route.query.id,
                results:this.list.filter(item=>{
                    if(item.resultId){
                        return { 
                            resultId:item.resultId,
                            sourceText:item.sourceText,
                            targetText:item.targetText
                        }
                    }
                })
            };
            this.batchSaveLoading = true;
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_termExtractionDetail_page_method_bathSaveEvent_message_1'));
                    this.initPage();
                }
            }).finally(()=>{
                this.batchSaveLoading = false;
            })

        },
        cancelBtnEvent(scope){
            this.list.splice(scope.$index, 1, {...this.list[scope.$index], edit:false})
        },
        handleTableSelectionChange(val){
            this.tableSelection = val;
        },
        handleTableRowClick(row, column, event){
            if (row.resultId && row.confirmed) {
                let index = -1;
                for (let i = 0; i < this.list.length; i++) {
                    const element = this.list[i];
                    if (element.resultId === row.resultId) {
                        index = i;
                        break;
                    }
                }
                if (index > 0) {
                    this.list[index].confirmed = false;
                    setTimeout(() => {
                        $(event.target).find('input').focus();
                    }, 100);
                }
            }
        },
        deleteBtnEvent(e,item){
            if(item){
                this.tableSelection = [item];
            }
            this.$refs.alertDialogDom.setShow(true);
        },
        deleteConfirmEvent(){
            // console.log('oooo::',this.tableSelection);
            let url = toolsApi.deleteResult;
            let postData=[];
            this.tableSelection.map(item=>{
                postData.push(item.resultId);
            })
            this.$ajax.post(url,{
                taskId:this.$route.query.id,
                ids:postData,
                toolType:'ET'
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_termExtractionDetail_page_method_deleteConfirmEvent_message_1'));
                    this.tableSelection = [];
                    this.initPage();
                }
            })
        },
        deleteCancelEvent(){
            this.tableSelection = [];
            this.$refs.tableList.clearSelection();
        },
        downloadFileEvent(command){
            this.downloadResult(command)
        },
        downloadResult(type){
            this.downloadLoading = true;
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                title: this.info.title,
                metadataIdList: [this.$route.query.id],
                metadataType: 'TOOLKIT_TE',
                moreOptions: {
                    exportFileType: type,
                }
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(()=>{
                this.downloadLoading = false;
            })
        },
        queryTermList(queryString, cb){
            this.dialog.termId = '';
            let url = `${termApi.termDB}?pageSize=100&pageNumber=1&name=${encodeURI(queryString)}&sourceLang=${this.info.sourceLang}&targetLang=${this.info.targetLang}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let records = res.data.records;
                    let tmpList = [];
                    records.map(item=>{
                        tmpList.push({
                            ...item,
                            value:item.name
                        })
                    })
                    cb(tmpList);
                }else{
                    cb([]);
                }
            })
        },
        dialogTermNameSelectEvent(item){
            // console.log('dialogMemNameSelectEvent:::',item,this.dialog)
            this.dialog.termId = item.termId;
        },
        queryTeamOptions() {
            let url = commonApi.queryTeamFilterOptions;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.dialog.teamOptions = res.data.teamFilters;
                    this.dialog.team = this.dialog.teamOptions[0].value
                }
            });
        },
        saveToTermDialogOpenEvent() {
            if(this.userInfo.organizationType === 'ENTERPRISE') {
                this.queryTeamOptions();
            }
        },
        saveToTermEvent(){
            if(!this.dialog.name){
                this.$message.error(this.$t('tools.tools_termExtractionDetail_page_method_saveToTermEvent_message_1'));
                return;
            }
            this.dialog.loading = true;
            let url = toolsApi.copyETResultToTerm;
            this.$ajax.post(url, {
                taskId: this.$route.query.id,
                termId: this.dialog.termId || undefined,
                termName: this.dialog.name,
                teamId: this.dialog.team,
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_termExtractionDetail_page_method_saveToTermEvent_message_2'));
                }
            }).finally(()=>{
                this.dialog.loading = false;
                this.dialog.show = false;
            })
            
        },
        sourceFrequencyChange() {
            if(this.sourceFrequencyTimer) {
                clearTimeout(this.sourceFrequencyTimer);
                this.sourceFrequencyTimer = null;
            }
            this.sourceFrequencyTimer = setTimeout(()=>{
                this.pageNumber = 1;
                this.initPage();
            }, 1000);
        }
    },
    mounted(){
        this.initPage(true);
    }
}
</script>
<style lang="scss" scoped>
.term-extraction-detail-page{
    // padding: 20px;
    .table-col-icon{
        margin-right: 15px;
        cursor: pointer;
        svg{
            font-size: 17px;
            color: #000000;
        }
    }
    .more-info {
        font-size: 20px;
    }
    .flex-justify-between{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

</style>
